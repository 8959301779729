<template>
  <v-container>
    <v-dialog v-model="dialogDelete" max-width="500px" v-if="selectedClient">
      <v-card>
        <v-card-title class="text-h5"
          >{{ $t('dialogMessages.deleteConfirmation') }}
          {{ selectedClient.name }}
          ?
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete">{{
            $t('buttons.cancel')
          }}</v-btn>
          <v-btn color="blue darken-1" text @click="deleteItemConfirm">{{
            $t('buttons.ok')
          }}</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row>
      <v-col align-self="center">
        {{ $t('title.clientView') }}
        <v-autocomplete
          v-if="clients"
          :items="selectItems"
          v-model="selectedClient"
          @change="selectClient"
          :label="$t('wizard.selectCompany')"
        ></v-autocomplete>
      </v-col>
    </v-row>
    <ClientForm :client="selectedClient" @saveClient="saveClientChanges" />
    <v-btn
      v-if="!isClient"
      :disabled="!selectedClient"
      color="error"
      class="ma-4"
      @click="deleteItem"
      >{{ $t('buttons.delete') }}</v-btn
    >
  </v-container>
</template>

<script>
import ClientForm from './../components/ClientForm.vue'
import { mapState } from 'vuex'
export default {
  components: {
    ClientForm,
  },
  data() {
    return {
      selectItems: [],
      selectedClient: null,
      dialogDelete: false,
      itemToDelete: null,
    }
  },
  created() {
    this.$store.dispatch('client/getClients')
  },
  computed: {
    ...mapState({
      clients: (state) => state.client.clients,
    }),
    isClient() {
      return this.$store.getters['user/role'] == 'client'
    },
  },
  methods: {
    deleteItem() {
      this.itemToDelete = this.selectedClient
      this.dialogDelete = true
    },
    deleteItemConfirm() {
      this.$store.dispatch('client/deleteClient', this.itemToDelete.id)
      this.closeDelete()
    },
    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.itemToDelete = null
        this.selectedClient = null
      })
    },
    selectClient(value) {
      this.selectedClient = value
    },
    saveClientChanges(formData) {
      if (formData.id) {
        this.$store.dispatch('client/updateClient', formData)
      } else {
        this.$store
          .dispatch('client/createClient', formData)
          .then((clientId) => {
            let client = this.clients.find((c) => c.id == clientId)
            this.selectClient(client)
          })
      }
    },
  },
  watch: {
    clients() {
      this.selectItems = this.clients.map((c) => ({
        text: c.name,
        value: c,
      }))
    },
  },
}
</script>

<style lang="scss" scoped></style>
